<template>
  <el-container class="auth-wrap bg-theme">
    <div class="content bg-f w-100">
      <div class="cus-header dp-flex justify-btw align-item-c title-color">
        <span>授权管理</span>
        <div>
          <el-popover
            placement="bottom-start"
            width="200"
            trigger="click"
          >
            <el-button
              slot="reference"
              type="primary"
              plain
              size="small"
            >
              添加授权
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-menu
              class="!border-r-0 w-full overflow-hidden"
              @select="selectAuth"
            >
              <template v-for="item in auths">
                <el-menu-item
                  :index="item.id"
                  v-if="!item.children"
                  :disabled="item.disabled"
                  :route="item.route"
                >
                  {{ item.name }}
                </el-menu-item>

                <el-submenu
                  v-if="item.children"
                  :index="item.id"
                >
                  <template slot="title">
                    {{ item.name }}
                  </template>
                  <el-menu-item
                    v-for="item in item.children"
                    :key="item.id"
                    :index="item.id"
                    :route="item.route"
                    >{{ item.name }}</el-menu-item
                  >
                </el-submenu>
              </template>
            </el-menu>
          </el-popover>
        </div>
      </div>

      <div class="searchWrap">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="mini"
        >
          <el-form-item label="">
            <el-input
              v-model="formInline.thirdUserId"
              placeholder="ID"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input
              v-model="formInline.thirdUserName"
              placeholder="名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input
              v-model="formInline.thirdUserEmail"
              placeholder="邮箱"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input
              v-model="formInline.bmId"
              placeholder="SA所在 bmid"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-select
              v-model="formInline.appType"
              placeholder="应用名称"
              clearable
            >
              <el-option
                v-for="item in appOptions"
                :key="item.appId"
                :label="item.desc"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="getUserList"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <cus-table
        :tableParams="tableParams"
        :tableData="tableData"
        :colData="colData"
        :tableHeightData="tableHeightData"
        class="w-full"
      >
        <template v-slot:colslot="{ scope, prop }">
          <el-dropdown
            size="small"
            v-if="prop == 'operate'"
            placement="bottom"
            @command="(command) => handleCommand(command, { flag: 'authOperates', row: scope.row })"
          >
            <el-button type="text">
              操作
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :disabled="
                  item.disabled ||
                  (index == 0 && scope.row.defaultLogin) ||
                  (index == 0 && scope.row.status == 'INVALID') ||
                  (index == 0 && scope.row.status == 'EXPIRED')
                "
                v-for="(item, index) in authOperates"
                :key="index"
                :command="item.id"
                >{{ item.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <div v-else-if="prop == 'type'">
            <span v-if="scope.row.thirdUser.thirdUserType === 'SYS_USER'">SA</span>
            <span v-else-if="scope.row.thirdUser.thirdUserType === 'LOGIN'">个号</span>
          </div>
          <div
            v-else-if="prop == 'thirdUser'"
            style="cursor: pointer"
            class="dp-flex align-item-c"
            @click="changeCurrentNum(scope.row)"
          >
            <div>
              <!--  v-if="scope.row.flag != 's'" -->
              <img
                class="mr-10"
                :src="scope.row[prop].thirdUserAvatarUrl"
                style="width: 32px; height: 32px; border-radius: 50%"
              />
              <!-- <i
                v-else
                class="el-icon-user color-theme mr-10"
                style="width: 32px; height: 32px;font-size:32px; "
              ></i> -->
            </div>
            <div style="line-height: initial">
              <p>
                <!-- :src="
                    `https://app.sinoclick.com/static/media/${
                      scope.row.flag == 'f'
                        ? 'fb.d03076a0'
                        : scope.row.flag == 'g'
                        ? 'google.d5988c9a'
                        : 'shopify.e092fd67'
                    }.svg`
                  " -->
                <img
                  src="https://app.sinoclick.com/static/media/fb.d03076a0.svg"
                  style="width: 12px; height: 12px; border-radius: 50%"
                />
                {{ scope.row[prop].displayName ? scope.row[prop].displayName : scope.row[prop].thirdUserName }}
                <span class="small-gray">({{ scope.row[prop].thirdUserEmail }})</span>
                <span
                  v-if="scope.row.defaultLogin"
                  class="static-btn"
                  style="margin-left: 5px"
                  >默认</span
                >
              </p>
              <p class="small-gray">ID：{{ scope.row[prop].thirdUserId }}</p>
            </div>
          </div>
          <div
            v-else-if="prop == 'status'"
            class="dp-flex align-item-c"
          >
            <span
              :class="[
                'small-circle',
                {
                  'bg-success': scope.row.status == 'VALID',
                  'bg-danger': scope.row.status == 'INVALID',
                  'bg-info': scope.row.status == 'EXPIRED',
                },
              ]"
            ></span>
            <span style="margin-left: 8px">{{
              scope.row.status == 'VALID' ? '有效' : scope.row.status == 'INVALID' ? '无效' : '过期'
            }}</span>
          </div>
          <div
            v-else-if="prop == 'adsPowerId'"
            class="dp-flex align-item-c"
          >
            <span style="margin-left: 8px">{{ scope.row.thirdUser.adsPowerId || '--' }}</span>
          </div>
          <div v-else-if="prop == 'link'">
            <el-button
              type="primary"
              plain
              size="mini"
              v-show="scope.row.appInfo.linkUrl"
              @click="copyHandle(scope.row.appInfo.linkUrl)"
              >复制链接</el-button
            >
            <!-- {{ scope.row.appInfo.linkUrl }} -->
          </div>
          <div v-else-if="prop == 'appName'">
            <span>{{ scope.row.appInfo.appName }}</span>
          </div>
          <div v-else-if="prop == 'labels'">
            <span v-if="scope.row.labels && scope.row.labels.length">
              <el-tag
                :key="tag.id"
                v-for="tag in scope.row.labels"
                :disable-transitions="false"
                style="margin: 5px"
                size="mini"
              >
                {{ tag.name }}
              </el-tag>
            </span>
            <span v-else> -- </span>
            <span
              ><el-button
                icon="el-icon-edit"
                type="text"
                size="mini"
                @click="editLabel(scope.row)"
              ></el-button
            ></span>
          </div>
        </template>
      </cus-table>
      <div
        class="page"
        style="margin-top: 10px; text-align: right"
      >
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[1, 10, 20, 30, 40, 50, 80, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-dialog
        title="编辑标签"
        :visible.sync="dialogFormVisible"
        width="600px"
      >
        <el-form
          :model="form"
          label-width="120px"
        >
          <el-form-item label="标签名称：">
            <el-select
              v-model="form.labels"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in labelList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            @click="dialogFormVisible = false"
            size="mini"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="editLabelSure"
            size="mini"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </el-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import cusTable from '@/views/components/cusTable';
import { setDefault, rename, unbind, bindGimpId, savePowerId } from '@/api/common/user';
import { myThirdLoginSetHasLabel, myThirdLoginSetHasLabelByPage } from '@/api/common/user';
import { queryLabel, saveLabel, delLabel, saveFbUserLabel } from '@/api/thirdUserLabel';
import { adConstsMap } from '@/api/creatAd';
export default {
  components: {
    cusTable,
  },
  data() {
    return {
      tableParams: { className: 'cus-table', isTableHeight: true },
      // 设置高度需要的数据
      tableHeightData: [
        { selector: '.navbar', cssProperty: 'height' },
        { selector: '.app-main', cssProperty: 'paddingTop' },
        { selector: '.app-main', cssProperty: 'paddingBottom' },
        { selector: '.auth-wrap', cssProperty: 'paddingTop' },
        { selector: '.auth-wrap', cssProperty: 'paddingBottom' },
        { selector: '.cus-header', cssProperty: 'height' },
        { selector: '.cus-header', cssProperty: 'marginBottom' },
        { selector: '.page', cssProperty: 'height' },
        { selector: '.page', cssProperty: 'marginTop' },
      ],
      auths: [
        {
          id: 1,
          name: 'Facebook授权',
          children: [
            { id: 'gads2', name: 'gads2', route: '/api/thirdoauth/fb' },
            {
              id: 'kokoonLine',
              name: 'kokoonLine',
              route: '/api/thirdoauth/kokoonLine',
            },
            {
              id: 'geroomall',
              name: 'geroomall',
              route: '/api/thirdoauth/geroomall',
            },
            {
              id: 'payson',
              name: '沛森',
              route: 'https://pesentec.com/api/thirdoauth/payson',
            },
            {
              id: 'chestnut',
              name: '可栗',
              route: 'https://kealitec.com/api/thirdoauth/chestnut',
            },
            {
              id: 'greatseff',
              name: 'SR-greatseff',
              route: 'https://pesentec.com/api/thirdoauth/greatseff',
            },
          ],
        },
        { id: 2, name: 'Google授权', disabled: true },
        { id: 3, name: 'Shopify授权', disabled: true },
      ],
      tableData: [],
      colData: [
        { label: '类型', prop: 'type' },
        { label: '凭证', prop: 'thirdUser', width: '370px' },
        { label: '最后授权时间', prop: 'auditUpdateTime', width: '180px' },
        { label: '状态', prop: 'status' },
        { label: '应用名称', prop: 'appName', width: '100px' },
        { label: '标签', prop: 'labels', width: '280px' },
        { label: 'SA原始创建链接', prop: 'link', width: '100px' },
        { label: 'adsPowerId', prop: 'adsPowerId' },
        { label: '权限', prop: 'scope', width: '280px' },
        { label: '操作', prop: 'operate' },
      ],
      authOperates: [
        { id: 1, name: '设为默认', disabled: false },
        { id: 2, name: '重新授权', disabled: false },
        { id: 3, name: '解除授权', disabled: false },
        { id: 4, name: '重命名', disabled: false },
        { id: 5, name: '添加到抓花费凭证', disabled: false },
        { id: 6, name: '绑定优化师', disabled: false },
        { id: 7, name: '添加adsPowerId', disabled: false },
      ],
      labelList: [],
      dialogFormVisible: false,
      form: {
        labels: [],
      },
      formInline: {
        thirdUserId: '',
        thirdUserName: '',
        thirdUserEmail: '',
        bmId: '',
        appType: '',
      },
      total: 0,
      pageSize: 10,
      currentPage: 1,
      appOptions: [], // 应用名称
    };
  },
  computed: {
    ...mapState('num', ['numLists']),
    ...mapGetters('num', ['validNumLists', 'defNum']),
  },
  methods: {
    getAppList() {
      adConstsMap().then((res) => {
        // console.log(res);
        this.appOptions = res.data?.ad?.EnumAppType || [];
      });
    },
    // 复制链接
    copyHandle(link) {
      const that = this;
      this.$copyText(link).then(
        function (e) {
          that.$message.success('复制成功！');
        },
        function (e) {
          that.$message.error('复制失败！');
        },
      );
    },
    // 设为默认
    setDefault(id, name) {
      this.$showLoading();
      setDefault({ id }).then((res) => {
        this.$hideLoading();
        if (res.comment == 'ok') {
          this.$message.success(`账号“${name}”已成功设为默认！`);
          this.$bus.$emit('trigger-myLoginList', 'facebook');
        }
      });
    },
    // 编辑标签
    editLabel(row) {
      this.form.labels = row.labels.map((v) => v.id);
      this.form.id = row.id;
      this.dialogFormVisible = true;
    },
    // 确定编辑标签
    editLabelSure() {
      let params = {
        fbUserId: this.form.id,
        labelIds: this.form.labels,
      };
      saveFbUserLabel(params).then((res) => {
        if (res.code == 0) {
          this.$message.success(`修改成功！`);
          this.dialogFormVisible = false;
          this.getUserList();
        }
      });
    },
    // 重命名
    rename(id, name) {
      this.$showLoading();
      rename({ id, displayName: name }).then((res) => {
        this.$hideLoading();
        if (res.comment == 'ok') {
          this.$message.success(`账户重命名成功！`);
          this.getUserList();
          this.$bus.$emit('trigger-myLoginList', 'facebook');
        }
      });
    },
    // 解除授权
    unbind(id) {
      this.$showLoading();
      unbind({ id }).then((res) => {
        this.$hideLoading();
        if (res.comment == 'ok') {
          this.$message.success(`解除授权成功！`);
          this.getUserList();
          this.$bus.$emit('trigger-myLoginList', 'facebook');
        }
      });
    },
    handleCommand(command, { flag, row }) {
      if (flag == 'authOperates') {
        switch (command) {
          // 设为默认
          case 1:
            this.setDefault(row.id, row.thirdUser.thirdUserName);
            break;
          // 重新授权
          case 2:
            window.open('/api/thirdoauth/fbUpdate');
            break;
          // 解除授权
          case 3:
            this.$confirm(`确定从个号列表中删除该个号吗？`, '解除授权确认', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'info',
            })
              .then(() => {
                this.unbind(row.id);
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: `已取消解除授权!`,
                });
              });
            break;
          // 重命名
          case 4:
            this.$prompt('请输入帐户新名字', '重命名', {
              inputValue: row.thirdUser.displayName,
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            })
              .then(({ value }) => {
                this.rename(row.id, value);
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消重命名',
                });
              });
            break;
          case 5:
            this.addToSpendCredential(row.id);
            break;
          case 6:
            // window.open(`https://gads2.giikin.com/api/thirdoauth/authUser?id=${row.id}`);
            this.$prompt('请输入单点账号id,多个请逗号隔开', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            })
              .then(({ value }) => {
                if (value) {
                  bindGimpId({ gimpIds: value, thirdUserId: row.id }).then((res) => {
                    if (res.code == 0) {
                      this.$message({
                        type: 'success',
                        message: '绑定成功',
                      });
                      this.getUserList();
                    }
                  });
                } else {
                  this.$message({
                    type: 'warning',
                    message: '请输入单点账号id',
                  });
                }
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '取消输入',
                });
              });
            break;
          case 7:
            // window.open(`https://gads2.giikin.com/api/thirdoauth/authUser?id=${row.id}`);
            this.$prompt('添加adsPowerId', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            })
              .then(({ value }) => {
                if (value) {
                  savePowerId({ adsPowerId: value, id: row.id }).then((res) => {
                    if (res.code == 0) {
                      this.$message({
                        type: 'success',
                        message: '添加成功',
                      });
                      this.getUserList();
                    }
                  });
                } else {
                  this.$message({
                    type: 'warning',
                    message: '请输入adsPowerId',
                  });
                }
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '取消输入',
                });
              });
            break;
        }
      } else {
        switch (command) {
          // Facebook授权
          case 1:
            window.open('/api/thirdoauth/fb');
            break;
          // Google授权
          case 2:
            break;
          // Shopify授权
          case 3:
            break;
        }
      }
    },
    addToSpendCredential(id) {
      let left = (window.screen.width - 500) / 2,
        top = (window.screen.height - 500) / 2;
      window.open('/api/thirdoauth/addToSpendCredential?id=' + id, '', `width=500,height=500,left=${left},top=${top}`);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserList();
    },
    getUserList() {
      let request = { ...this.formInline };
      if (!request.appType) delete request.appType;
      myThirdLoginSetHasLabelByPage({
        platform: 'facebook',
        ...request,
        pageNo: this.currentPage,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.comment == 'ok') {
          this.tableData = res.data.content;
          this.total = res.data.totalElements;
        }
      });
    },
    getList() {
      // this.searchForm.seqNo = this.searchForm.seqNo?(this.searchForm.seqNo):''
      queryLabel({}).then((res) => {
        console.log(res);
        this.labelList = res.data;
      });
    },
    // 点击切换账号
    async changeCurrentNum(row) {
      let statusId = row.status == 'VALID' ? 1 : 2;
      let labelId = row.labels && row.labels.length ? row.labels[0].id : statusId || null;
      this.$store.commit('num/selectNumLable', labelId);
      await this.$store.dispatch('num/setSelectedNum', {
        selectedNum: row.id,
      });
      localStorage.removeItem('activeAdAccount');
      this.$bus.$emit('trigger-getAdAccounts', {});
    },
    selectAuth(index, indexPath, el) {
      // console.log(el);
      window.open(el.route);
    },
  },
  mounted() {
    this.getAppList();
    this.getUserList();
    this.getList();
  },
};
</script>
<style lang="scss">
@import '@/styles/commonly';
.auth-wrap {
  padding: 8px;
  .content {
    padding: 0 32px;
  }
  .cus-header {
    padding: 16px 0;
    margin-bottom: 8px;
  }
  .cus-table.el-table {
    color: rgba(0, 0, 0, 0.85);
    th {
      background: #fafafa !important;
    }
    thead {
      tr th {
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .cell {
      padding: {
        left: 16px;
        right: 16px;
      }
    }
    th,
    td {
      padding: {
        top: 16px;
        bottom: 16px;
      }
    }
    .el-table__row:nth-child(2n-1) {
      background: #f8f8fa;
    }
    i {
      margin-right: 0;
    }
    .el-table__body-wrapper {
      height: auto !important;
    }
  }
}
.static-btn {
  color: #3b87e5;
  border: 1px solid #c8e0ff;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 12px;
  cursor: pointer;
  word-break: keep-all;
}
.small-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.menu {
  ::deep .el-menu-item {
    height: 40px;
    line-height: 40px;
  }
}
</style>
