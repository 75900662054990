
import request from '@/utils/request'
export function saveLabel(data) {
    return request({
      url: '/label/saveLabel',
      method:'post',
      data: data,
      transformRequest: [function (data) {
          return JSON.stringify(data)
      }]
    })
  }
  // /job/sessionJobsCount
  export function delLabel(data) {
    return request({
      url: '/label/delLabel',
      method:'post',
      data: data,
      transformRequest: [function (data) {
          return JSON.stringify(data)
      }]
    })
  }
  export function queryLabel(data) {
    return request({
      url: '/label/queryLabel',
      method:'post',
      data: data,
      transformRequest: [function (data) {
          return JSON.stringify(data)
      }]
    })
  }
  export function saveFbUserLabel(data) {
    return request({
      url: '/label/saveFbUserLabel',
      method:'post',
      data: data,
      transformRequest: [function (data) {
          return JSON.stringify(data)
      }]
    })
  }
  export function delFbUserLabel(data) {
    return request({
      url: '/label/delFbUserLabel',
      method:'post',
      data: data,
      transformRequest: [function (data) {
          return JSON.stringify(data)
      }]
    })
  }
  export function saveLabelFbUsers(data) {
    return request({
      url: '/label/saveLabelFbUsers',
      method:'post',
      data: data,
      transformRequest: [function (data) {
          return JSON.stringify(data)
      }]
    })
  }
  